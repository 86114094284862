.form{
    width: 100%;
}


@media screen and (max-width: 400px) {
    .form{
        width: 96%;
    }
    
}
.form_input{
    width: 100%;
    height: 4em;
    border: 2px solid;
    border-radius: 20px;
    padding-left: 7px;
    font-family: 'Rubik';
}
.css-1fyyp8j-MuiGrid-root{
    display: flex;
}
.css-1idn90j-MuiGrid-root{
    display: flex;
}
.message{
    height: 6em;
}
